import { useState, useEffect, useContext } from "react";

import { Box, Typography } from "@mui/material";

import Sidebar from "./Sidebar";
import PresetChooser from "./PresetChooser";
import { PergPresetsData, SavedState } from "./DataTypes";
import { config } from "../Config";
import PergState from "./PergState";
import ActionBar from "./ActionBar";

const localStorageKey = "ree_perg_state";

export default function PerbIndex(props: {
  setLoading: (isLoading: boolean) => void;
  setLoadingLevel: (val: boolean) => void;
}) {
  const [presetsData, setPresetsData] = useState<PergPresetsData>();
  const [savedState, setSavedState] = useState<SavedState>();
  useEffect(() => {
    (window as any).sofa_render_finished = function (action: {
      filename: string;
    }) {
      console.log("SOFA RENDER FINISHED :)", action);
      const filesDirUrl =
        config.filesServerOverride ?? (window as any).reeFilesUrl;
      if (filesDirUrl === undefined) {
        throw Error();
      }
      const fileUrl = `${filesDirUrl}/${action.filename}`;
      fetch(fileUrl, {})
        .then((res) => res.blob())
        .then((blob) => {
          if (blob != null) {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = action.filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        });
    };
  }, []);
  useEffect(() => {
    if (savedState !== undefined) {
      window.localStorage.setItem(localStorageKey, JSON.stringify(savedState));
    }
  }, [savedState]);
  useEffect(() => {
    /*
    (window as any).reeLevelRestore = async function () {
      console.log(
        "reeLevelRestore() called in pergola index. Ignoring for now"
      );
    };
    */
    const getPresets = async () => {
      const response: PergPresetsData = await fetch(
        `${config.apiUrl}/items/perg_presets?fields=*,translations.*`
      ).then((response) => response.json());
      setPresetsData(response);
      function setDefaultState() {
        setSavedState({
          preset: undefined,
          userTypes: {
            maxCount: 0,
            presets: [],
          },
        });
      }
      const stateRaw = window.localStorage.getItem(localStorageKey);
      if (stateRaw !== null) {
        try {
          const state = JSON.parse(stateRaw);
          if (
            state.preset !== null &&
            state.preset.isUserPreset &&
            response.data.findIndex((it) => it.id === state.preset.id) === -1
          ) {
            state.preset = undefined;
          }
          setSavedState(state);
        } catch (err) {
          console.error(err);
          setDefaultState();
        }
      } else {
        setDefaultState();
      }
    };
    getPresets();
  }, []);
  return (
    <PergState>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "none",
        }}
      >
        <PresetChooser
          presetsData={presetsData}
          userPresets={savedState?.userTypes.presets}
          curPreset={savedState?.preset}
          onPresetClick={(preset) => {
            if (savedState === undefined) {
              throw Error();
            }
            const newState = { ...savedState };
            newState.preset = {
              isUserPreset: false,
              id: preset.id,
            };
            setSavedState(newState);
          }}
          onUserPresetClick={(preset) => {
            if (savedState === undefined) {
              throw Error();
            }
            const newState = { ...savedState };
            newState.preset = {
              isUserPreset: true,
              id: preset.id,
            };
            setSavedState(newState);
          }}
          onUserPresetDelete={(preset) => {
            if (savedState === undefined) {
              throw Error();
            }
            const newState = { ...savedState };
            newState.userTypes.presets = newState.userTypes.presets.filter(
              (it) => it.id === preset.id
            );
            setSavedState(newState);
          }}
          showCurrentElements={() => alert("current elements are")}
        />
        <Sidebar
          presetsData={presetsData}
          userPresets={savedState?.userTypes.presets}
          curPreset={savedState?.preset}
          setUserPresets={(presets) => {
            if (savedState === undefined) {
              throw Error();
            }
            const newState = { ...savedState };
            newState.userTypes.presets = presets;
            setSavedState(newState);
          }}
        />
        <ActionBar setLoadingLevel={props.setLoadingLevel} />
      </Box>
    </PergState>
  );
}
